import { api } from "shared/api";
import { sortByField } from "shared/helpers";

const routesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listRoutePoints: builder.query({
      query: ({ projectId, filters }) =>
        `projects/${projectId}/route-points/filters?page=${filters.page}&limit=${filters.limit}&managerCodes=${filters.managerCodes}&commands=${filters.commands}&routeCodes=${filters.routeCodes}&periodicityList=${filters.periodicityList}&address=${filters.address}&daysOfWeek=${filters.daysOfWeek}&duplicates=${filters.duplicates}&sortBy=${filters.sort}&attributeCodes1=${filters.attributeCodes1}&attributeCodes2=${filters.attributeCodes2}`,
      providesTags: ["Routes"],
    }),
    listMapRoutePoints: builder.query({
      query: ({ projectId, managerCodes, commands, periodicityList, routeCodes, daysOfWeek, attributeCodes1, attributeCodes2 }) =>
        `projects/${projectId}/route-points/maps/filters?managerCodes=${managerCodes}&commands=${commands}&periodicityList=${periodicityList}&routeCodes=${routeCodes}&daysOfWeek=${daysOfWeek}&attributeCodes1=${attributeCodes1}&attributeCodes2=${attributeCodes2}`,
      providesTags: ["Routes"],
    }),
    listRoutesReport: builder.query({
      query: ({ projectId, cycle = 'FOUR_WEEK_CYCLE' }) =>
        `projects/${projectId}/routes/reports?parameters=TO,PR,PERCENT&workCycle=${cycle}`,
      transformResponse: (response) =>
        sortByField(response.reportRouteItems, "routeName"),
    }),
    setRoute: builder.mutation({
      query: ({ projectId, ...body }) => ({
        url: `projects/${projectId}/route-points/routes`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Routes", "States"],
    }),
    setDay: builder.mutation({
      query: ({ projectId, ...body }) => ({
        url: `projects/${projectId}/route-points/days-of-week`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Routes", "States", "Days"],
    }),
    deletePoint: builder.mutation({
      query: ({ projectId, routeId, id }) => ({
        url: `projects/${projectId}/routes/${routeId}/routePoints/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Routes", "States"],
    }),
    getExcelRoutes: builder.query({
      query: ({ projectId }) => `projects/${projectId}/routes`,
      transformResponse: (response) => {
        // attach rowNumbers to response
        if (response.length <= 1) return response;
        if (response[1].rowNumber === 0) {
          return response
            .sort((a, b) => {
              const r1 = a.routeCode.toLowerCase();
              const r2 = b.routeCode.toLowerCase();

              if (r1 > r2) return 1;
              if (r1 < r2) return -1;
              return 0;
            })
            .map((item, index) => {
              // eslint-disable-next-line no-param-reassign
              item.rowNumber = index;
              return item;
            });
        }
        return response;
      },
      providesTags: ["Routes"],
    }),
    checkNewRoute: builder.mutation({
      query: ({ projectId, newRoute }) => ({
        url: `projects/${projectId}/routes?checkOnly=true`,
        method: "PUT",
        body: [newRoute],
      }),
    }),
    saveNewRoute: builder.mutation({
      query: ({ projectId, newRoute }) => ({
        url: `projects/${projectId}/routes?checkOnly=false`,
        method: "PUT",
        body: [newRoute],
      }),
      invalidatesTags: ["Routes"],
    }),
    deleteRoutes: builder.mutation({
      query: ({ projectId, routeIds }) => ({
        url: `projects/${projectId}/routes?routeId=[${routeIds.toString}]`,
        method: "DELETE",
      }),
      invalidatesTags: ["Routes"],
    }),
  }),
});

export const {
  useListRoutePointsQuery,
  useListMapRoutePointsQuery,
  useListRoutesReportQuery,
  useSetRouteMutation,
  useSetDayMutation,
  useDeletePointMutation,
  useGetExcelRoutesQuery,
  useCheckNewRouteMutation,
  useSaveNewRouteMutation,
  useDeleteRoutesMutation,
} = routesApi;
