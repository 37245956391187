import { api } from "shared/api";

export const projectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listProjects: builder.query({
      query: () => "projects",
      transformResponse: (response) =>
        response.sort((a) => (a.name === "Срочные точки" ? -1 : 0)),
      providesTags: ["Projects"],
    }),
    getContractGroups: builder.query({
      query: (branchId) => ({
        url: `/branches/${branchId}/contract-groups`,
      }),
    }),
    getBranches: builder.query({
      query: () => ({
        url: `/branches/user`,
      }),
    }),
    listContracts: builder.query({
      query: ({ id }) => `projects/${id}/filters/external/branches/contracts`,
    }),
    listRoutes: builder.query({
      query: ({ id }) => `projects/${id}/filters/external/users/routes`,
    }),
    listRoutesOptimization: builder.query({
      query: ({ projectId }) => `projects/${projectId}/routes/messages`,
    }),
    changeRoutesOptimization: builder.mutation({
      query: ({ projectId, ...body }) => ({
        url: `projects/${projectId}/routes/routePoints/orders`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Routes", "Days", "Coordinates"],
    }),
    createRoutesProjectJob: builder.mutation({
      query: ({ projectId, ...body }) => ({
        url: `projects/${projectId}/routes/job`,
        method: "POST",
        body,
      }),
    }),
    createContractsProjectJob: builder.mutation({
      query: ({ projectId, ...body }) => ({
        url: `projects/${projectId}/logistic-points/job`,
        method: "POST",
        body,
      }),
    }),
    getProjectJob: builder.query({
      query: ({ projectId, jobId }) => `projects/${projectId}/${jobId}`,
    }),
    updateProjectName: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `projects/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Projects"],
    }),
    createProject: builder.mutation({
      query: (body) => ({
        url: "projects",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects"],
    }),
    deleteProject: builder.mutation({
      query: ({ id }) => ({
        url: `projects/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Projects"],
    }),
    exportData: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `projects/${id}/export-eus`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Projects"],
    }),
    deleteProjectData: builder.mutation({
      query: ({ id, dataTypes }) => ({
        url: `projects/${id}/data?${dataTypes.routes && "dataTypes=ROUTE"}&${dataTypes.points && "dataTypes=POINT"
          }&${dataTypes.routePoints && "dataTypes=ROUTE_POINT"}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Routes", "Days", "Coordinates"],
    }),
    copyDeliveryAreas: builder.mutation({
      query: ({ projectId, body }) => ({
        url: `/projects/${projectId}/delivery-areas/copy-by-branch-and-group`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useListProjectsQuery,
  useListContractsQuery,
  useListRoutesQuery,
  useListRoutesOptimizationQuery,
  useChangeRoutesOptimizationMutation,
  useCreateRoutesProjectJobMutation,
  useCreateContractsProjectJobMutation,
  useLazyGetProjectJobQuery,
  useUpdateProjectNameMutation,
  useDeleteProjectMutation,
  useCreateProjectMutation,
  useExportDataMutation,
  useDeleteProjectDataMutation,
  useCopyDeliveryAreasMutation,
  useLazyGetBranchesQuery,
  useLazyGetContractGroupsQuery,

} = projectsApi;
