import { useEffect, useState } from "react";
import { useLazyGetBranchesQuery, useLazyGetContractGroupsQuery, useCopyDeliveryAreasMutation } from "shared/api/projects";
import { Button, Checkbox, ErrorText, Flex, Popup, Select, Text } from "shared/ui";

const CopyDeliveryAreas = ({ project }) => {
    const [branches, setBranches] = useState([]);
    const [contractGroups, setContractGroups] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [deleteDeliveryArea, setDeleteDeliveryArea] = useState(false);
    const [serverError, setServerError] = useState("");

    const [getBranches] = useLazyGetBranchesQuery();
    const [getContractGroups] = useLazyGetContractGroupsQuery();
    const [copyDeliveryAreas] = useCopyDeliveryAreasMutation();

    const loadContractGroups = async (branchId) => {
        try {
            const response = await getContractGroups(branchId).unwrap();
            const responseContractGroups = response.contractGroups;

            setSelectedGroup(responseContractGroups[0].id);
            setContractGroups(responseContractGroups);
        } catch (error) {
            setServerError(error?.data?.message || "Ошибка загрузки групп контрактов");
        }
    };

    const loadBranches = async () => {
        try {
            const response = await getBranches().unwrap();
            setBranches(response);
            setSelectedBranch(response[0].id);

            if (response.length === 1) {
                loadContractGroups(response[0].id);
            }
        } catch (error) {
            setServerError(error?.data?.message || "Ошибка загрузки филиалов");
        }
    };

    useEffect(() => {
        loadBranches();
    }, []);

    useEffect(() => {
        if (selectedBranch) {
            loadContractGroups(selectedBranch);
        }
    }, [selectedBranch]);

    const handleCopy = async () => {
        try {
            const response = await copyDeliveryAreas({
                projectId: project.id,
                body: {
                    branchId: selectedBranch,
                    groupId: selectedGroup,
                    deleteDeliveryArea
                }
            }).unwrap();
            if (response.success === true) {
                window.location.href = `/areas/${project.id}`;
            }
        } catch (error) {
            setServerError(error?.data?.message || "Ошибка копирования зон доставки");
        }
    };

    const handleBranchChange = (e) => {
        setServerError("");
        setSelectedBranch(Number(e.target.value));
    };

    const handleGroupChange = (e) => {
        setServerError("");
        setSelectedGroup(Number(e.target.value));
    };

    const handleCheckboxChange = (e) => {
        setServerError("");
        setDeleteDeliveryArea(e.target.checked);
    };

    return (
        <Popup isCenter title="Копирование зон доставки">
            <Flex flexDirection="column" mt="20px">
                <Flex flexDirection="column" mb="20px">
                    <Text variant="body3" color="color3" mb="4px">
                        Выберите филиал
                    </Text>
                    <Select
                        value={selectedBranch}
                        onChange={handleBranchChange}
                        options={branches.map(branch => ({
                            id: branch.id,
                            name: branch.branchName,
                            value: branch.id,
                        }))}
                    />
                </Flex>

                {selectedBranch && (
                    <Flex flexDirection="column" mb="20px">
                        <Text variant="body3" color="color3" mb="4px">
                            Выберите группу контрактов
                        </Text>
                        <Select
                            value={selectedGroup}
                            onChange={handleGroupChange}
                            options={contractGroups.map(group => ({
                                id: group.id,
                                name: group.name,
                                value: group.id,
                            }))}
                        />
                    </Flex>
                )}

                <Flex flexDirection="row" alignItems="center" mb="20px">
                    <Flex mr="7px">
                        <Checkbox
                            checked={deleteDeliveryArea}
                            onChange={handleCheckboxChange}
                        />
                    </Flex>
                    <Text variant="body1">Удалить устаревшие зоны доставки</Text>
                </Flex>

                <Button
                    width="100%"
                    height="48px"
                    color="#2D8AF6"
                    text="КОПИРОВАТЬ ЗОНЫ"
                    onClick={handleCopy}
                    disabled={!selectedBranch || !selectedGroup}
                />
            </Flex>
            <ErrorText errorText={serverError} />
        </Popup>
    );
};

export default CopyDeliveryAreas;