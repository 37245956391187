/* eslint-disable consistent-return */
import {
  resetCommandFilter,
  resetManagerFilter,
  setAddressFilter,
  setCommandFilter,
  setCycleFilter,
  setDaysFilter,
  setManagerFilter,
  setRouteFilter,
} from "app/store/reducer/days";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useListFiltersRoutesCommandsDuplicatesQuery,
  useListFiltersRoutesUsersDuplicatesQuery,
} from "shared/api/filters";
import { CYCLES, DAYS_OF_WEEK_COLOR, DAYS_OF_WEEK_RU } from "shared/config";
import { beautifyRouteName, sortByField } from "shared/helpers";
import { getUserRole } from "shared/libs/jwt-decode";
import { Flex, Search, SearchDropdown, Text } from "shared/ui";

import { Cycles as CyclesUI, Days } from "../../../ui";

export const ManagerDropdown = () => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { frontendFilters } = useSelector((state) => state.rootReducer.days);
  const { data: filters, isSuccess } = useListFiltersRoutesUsersDuplicatesQuery(
    {
      projectId: projectInfo.id,
      managers: frontendFilters.managerCodes,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );

  function onManagerSelect(item) {
    dispatch(setManagerFilter([item.fullNameCode]));
    setIsDropdownOpen(false);
  }

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const onToggle = (isOpen) => {
    if (isOpen === false) onSearch("");
    setIsDropdownOpen((prev) => !prev);
  };

  const onResetSearch = () => {
    onSearch("");
    setIsDropdownOpen(false);
  };

  const onResetAllCheckboxes = () => {
    dispatch(resetManagerFilter());
    setIsDropdownOpen(false);
  };

  if (isSuccess) {
    return (
      <SearchDropdown
        isDropdownOpen={isDropdownOpen}
        toggleText={
          frontendFilters?.managerCodes?.length
            ? filters?.users.find(
                (user) =>
                  user?.fullNameCode === frontendFilters?.managerCodes[0],
              ).shortName
            : "Выбрать"
        }
        searchValue={searchValue}
        onToggle={onToggle}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
        onResetAllCheckboxes={onResetAllCheckboxes}
        selectedText
        selectedCount={frontendFilters?.managerCodes?.length}
      >
        {filters?.users &&
          sortByField(filters?.users, "shortName").map(
            (item) =>
              item.shortName
                .toLowerCase()
                .includes(searchValue.toLowerCase()) && (
                <SearchDropdown.Item
                  key={item.fullNameCode}
                  isSelected={frontendFilters?.managerCodes.includes(
                    item.fullNameCode,
                  )}
                  onClick={() => onManagerSelect(item)}
                >
                  <Flex ml="7px">
                    <Text variant="body1">{item.shortName}</Text>
                  </Flex>
                </SearchDropdown.Item>
              ),
          )}
      </SearchDropdown>
    );
  }

  return null;
};

export const CommandDropdown = () => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { frontendFilters } = useSelector((state) => state.rootReducer.days);
  const { data: filters, isSuccess } =
    useListFiltersRoutesCommandsDuplicatesQuery(
      {
        projectId: projectInfo.id,
        commands: frontendFilters.commands,
      },
      { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
    );

  function onManagerSelect(item) {
    dispatch(setCommandFilter([item]));
    setIsDropdownOpen(false);
  }

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const onToggle = (isOpen) => {
    if (isOpen === false) onSearch("");
    setIsDropdownOpen((prev) => !prev);
  };

  const onResetSearch = () => {
    onSearch("");
    setIsDropdownOpen(false);
  };

  const onResetAllCheckboxes = () => {
    dispatch(resetCommandFilter());
    setIsDropdownOpen(false);
  };

  if (isSuccess) {
    return (
      <SearchDropdown
        isDropdownOpen={isDropdownOpen}
        toggleText={
          frontendFilters?.commands?.length
            ? filters?.commandsResponse.find(
                (command) => command === frontendFilters?.commands[0],
              )
            : "Выбрать"
        }
        searchValue={searchValue}
        onToggle={onToggle}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
        onResetAllCheckboxes={onResetAllCheckboxes}
        selectedText
        selectedCount={frontendFilters?.commands?.length}
      >
        {filters?.commandsResponse.map(
          (item) =>
            item.toLowerCase().includes(searchValue.toLowerCase()) && (
              <SearchDropdown.Item
                key={item}
                isSelected={frontendFilters?.managerCodes.includes(item)}
                onClick={() => onManagerSelect(item)}
              >
                <Flex ml="7px">
                  <Text variant="body1">{item}</Text>
                </Flex>
              </SearchDropdown.Item>
            ),
        )}
      </SearchDropdown>
    );
  }

  return null;
};

export const RouteDropdown = () => {
  const dispatch = useDispatch();
  const role = getUserRole();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { frontendFilters } = useSelector((state) => state.rootReducer.days);
  const { data: userFilters, isSuccess: userFiltersIsSuccess } =
    useListFiltersRoutesUsersDuplicatesQuery(
      {
        projectId: projectInfo.id,
        managers: frontendFilters.managerCodes,
      },
      {
        skip: !projectInfo.id || role === "ROUTE_ENG",
        refetchOnMountOrArgChange: true,
      },
    );
  const { data: routeEngFilters, isSuccess: routeEngFiltersIsSuccess } =
    useListFiltersRoutesCommandsDuplicatesQuery(
      {
        projectId: projectInfo.id,
        commands: frontendFilters?.commands,
      },
      {
        skip: !projectInfo.id || role !== "ROUTE_ENG",
        refetchOnMountOrArgChange: true,
      },
    );
  const filters = role === "ROUTE_ENG" ? routeEngFilters : userFilters;

  function onRouteSelect(item) {
    dispatch(
      setRouteFilter({ routeCode: [item.routeCode], routeId: [item.routeId] }),
    );
    setIsDropdownOpen(false);
  }

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const onToggle = (isOpen) => {
    if (isOpen === false) onSearch("");
    setIsDropdownOpen((prev) => !prev);
  };

  const onResetSearch = () => {
    onSearch("");
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    if (userFiltersIsSuccess || routeEngFiltersIsSuccess) {
      dispatch(
        setRouteFilter({
          routeCode: [sortByField(filters?.routes, "routeCode")[0]?.routeCode],
          routeId: [sortByField(filters?.routes, "routeCode")[0]?.routeId],
        }),
      );
    }
  }, [userFiltersIsSuccess, routeEngFiltersIsSuccess]);

  if (userFiltersIsSuccess || routeEngFiltersIsSuccess) {
    return (
      <SearchDropdown
        isDropdownOpen={isDropdownOpen}
        toggleText={
          frontendFilters?.routeCodes?.length
            ? beautifyRouteName(
                filters?.routes.find(
                  (route) =>
                    route?.routeCode === frontendFilters?.routeCodes[0],
                )?.routeName,
              )
            : "Выбрать"
        }
        searchValue={searchValue}
        onToggle={onToggle}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
        selectedCount={frontendFilters?.routeCodes?.length}
      >
        {filters?.routes &&
          sortByField(filters?.routes, "routeName").map(
            (item) =>
              item.routeName
                .toLowerCase()
                .includes(searchValue.toLowerCase()) && (
                <SearchDropdown.Item
                  key={item.routeId}
                  isSelected={frontendFilters?.routeCodes.includes(
                    item.routeCode,
                  )}
                  onClick={() => onRouteSelect(item)}
                >
                  <Flex ml="7px">
                    <Text variant="body1" color={item.color}>
                      {item.routeName}
                    </Text>
                  </Flex>
                </SearchDropdown.Item>
              ),
          )}
      </SearchDropdown>
    );
  }

  return null;
};

// export const RouteEngineerRouteDropdown = () => {
//   const dispatch = useDispatch();
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [searchValue, setSearchValue] = useState("");
//
//   const { projectInfo } = useSelector(
//     (state) => state.rootReducer.currentProject,
//   );
//   const { frontendFilters } = useSelector((state) => state.rootReducer.days);
//   const { data: filters, isSuccess } =
//     useListFiltersRoutesCommandsDuplicatesQuery(
//       {
//         projectId: projectInfo.id,
//         commands: frontendFilters?.commands,
//       },
//       {
//         skip: !projectInfo.id,
//         refetchOnMountOrArgChange: true,
//       },
//     );
//
//   function onRouteSelect(route) {
//     dispatch(setMultipleRouteFilter(route));
//   }
//
//   const onSearch = (value) => {
//     setSearchValue(value);
//   };
//
//   const onToggle = (isOpen) => {
//     if (isOpen === false) onSearch("");
//     setIsDropdownOpen((prev) => !prev);
//   };
//
//   const onResetSearch = () => {
//     onSearch("");
//     setIsDropdownOpen(false);
//   };
//
//   const onResetAllCheckboxes = () => {
//     dispatch(resetMultipleRouteFilter());
//     setIsDropdownOpen(false);
//   };
//
//   useEffect(() => {
//     if (isSuccess) {
//       dispatch(
//         setRouteFilter({
//           routeCode: [sortByField(filters?.routes, "routeCode")[0]?.routeCode],
//           routeId: [sortByField(filters?.routes, "routeCode")[0]?.routeId],
//         }),
//       );
//     }
//   }, [isSuccess]);
//
//   if (isSuccess) {
//     return (
//       <SearchDropdown
//         multiselect
//         selectedText
//         isDropdownOpen={isDropdownOpen}
//         toggleText={
//           frontendFilters?.routeCodes?.length === 1
//             ? beautifyRouteName(
//               filters?.routes.find(
//                 (route) =>
//                   route?.routeCode === frontendFilters?.routeCodes[0],
//               )?.routeName,
//             )
//             : `Выбрано ${frontendFilters?.routeCodes?.length}`
//         }
//         searchValue={searchValue}
//         onToggle={onToggle}
//         onSearch={onSearch}
//         onResetSearch={onResetSearch}
//         selectedCount={frontendFilters?.routeCodes?.length}
//         onResetAllCheckboxes={onResetAllCheckboxes}
//       >
//         {filters?.routes &&
//           sortByField(filters?.routes, "routeName").map(
//             (item) =>
//               item.routeName
//                 .toLowerCase()
//                 .includes(searchValue.toLowerCase()) && (
//                 <SearchDropdown.Item
//                   key={item.routeId}
//                   isSelected={frontendFilters?.routeCodes.includes(
//                     item.routeCode,
//                   )}
//                 >
//                   <Checkbox
//                     filledColor={item.color}
//                     emptyColor={item.color}
//                     checked={frontendFilters?.routeCodes?.includes(
//                       item.routeCode,
//                     )}
//                     onChange={() => onRouteSelect(item)}
//                   />
//                   <Flex ml="7px">
//                     <Text variant="body1">{item.routeName}</Text>
//                   </Flex>
//                 </SearchDropdown.Item>
//               ),
//           )}
//       </SearchDropdown>
//     );
//   }
//
//   return null;
// };

export const AddressSearch = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [isMounted, setIsMounted] = useState(false);
  const { frontendFilters } = useSelector((state) => state.rootReducer.days);

  useEffect(() => {
    if (isMounted && search !== frontendFilters.address) {
      const setFilterWithDelay = setTimeout(() => {
        dispatch(setAddressFilter(search));
      }, 300);

      return () => {
        clearTimeout(setFilterWithDelay);
      };
    }
  }, [search]);

  useEffect(() => {
    setSearch(frontendFilters.address);
  }, [frontendFilters.address]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Search
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      onReset={() => setSearch("")}
    />
  );
};

export const VisitDays = () => {
  const role = getUserRole();
  const dispatch = useDispatch();
  const [selectedDays, setSelectedDays] = useState([]);
  const [isMounted, setIsMounted] = useState(false);

  const { daysOfWeek } = useSelector(
    (state) => state.rootReducer.days.frontendFilters,
  );

  const onSelect = (day) => {
    if (role === "ROUTE_ENG") {
      // multiple select for route engineers
      if (selectedDays.includes(day)) {
        setSelectedDays((prev) => prev.filter((v) => v !== day));
      } else {
        setSelectedDays((prev) => [...prev, day]);
      }
    } else {
      // single select for others
      // eslint-disable-next-line no-lonely-if
      if (!selectedDays.includes(day)) {
        setSelectedDays([day]);
      }
    }
  };

  useEffect(() => {
    if (isMounted && !isEqual(selectedDays, daysOfWeek)) {
      if (selectedDays.length === 8) setSelectedDays([]);

      const setFilterWithDelay = setTimeout(() => {
        dispatch(setDaysFilter(selectedDays));
      }, 300);

      return () => {
        clearTimeout(setFilterWithDelay);
      };
    }
  }, [selectedDays]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    setSelectedDays(daysOfWeek);
  }, [daysOfWeek]);

  const days = [
    {
      name: DAYS_OF_WEEK_RU.MONDAY,
      color: DAYS_OF_WEEK_COLOR.MONDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.TUESDAY,
      color: DAYS_OF_WEEK_COLOR.TUESDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.WEDNESDAY,
      color: DAYS_OF_WEEK_COLOR.WEDNESDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.THURSDAY,
      color: DAYS_OF_WEEK_COLOR.THURSDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.FRIDAY,
      color: DAYS_OF_WEEK_COLOR.FRIDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.SATURDAY,
      color: DAYS_OF_WEEK_COLOR.SATURDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.SUNDAY,
      color: DAYS_OF_WEEK_COLOR.SUNDAY,
    },
    ...(role === "ROUTE_ENG"
      ? [
          {
            name: "N/A",
            color: DAYS_OF_WEEK_COLOR.NA,
          },
        ]
      : []),
  ];

  return <Days days={days} selectedDays={selectedDays} onSelect={onSelect} />;
};

export const Cycles = () => {
  const dispatch = useDispatch();
  const [selectedCycle, setSelectedCycle] = useState(CYCLES.EVERY_WEEK);
  const [isMounted, setIsMounted] = useState(false);

  const { cyclePoint } = useSelector(
    (state) => state.rootReducer.days.frontendFilters,
  );

  const onSelect = (cycle) => {
    if (cycle === selectedCycle) {
      setSelectedCycle(CYCLES.EVERY_WEEK);
    } else {
      setSelectedCycle(cycle);
    }
  };

  useEffect(() => {
    if (isMounted && cyclePoint !== selectedCycle) {
      const setFilterWithDelay = setTimeout(() => {
        dispatch(setCycleFilter(selectedCycle));
      }, 300);

      return () => {
        clearTimeout(setFilterWithDelay);
      };
    }
  }, [selectedCycle, dispatch]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    setSelectedCycle(cyclePoint);
  }, [cyclePoint]);

  const items = [
    {
      id: 0,
      text: 1,
      cycle: CYCLES.FIRST_WEEK,
    },
    {
      id: 1,
      text: 2,
      cycle: CYCLES.SECOND_WEEK,
    },
    {
      id: 2,
      text: 3,
      cycle: CYCLES.THIRD_WEEK,
    },
    {
      id: 3,
      text: 4,
      cycle: CYCLES.FOURTH_WEEK,
    },
    {
      id: 4,
      text: "12\\1",
      cycle: CYCLES.FIRST_WEEK_CYCLE_12,
    },
    {
      id: 5,
      text: "12\\2",
      cycle: CYCLES.SECOND_WEEK_CYCLE_12,
    },
    {
      id: 6,
      text: "12\\3",
      cycle: CYCLES.THIRD_WEEK_CYCLE_12,
    },
    {
      id: 7,
      text: "12\\4",
      cycle: CYCLES.FOURTH_WEEK_CYCLE_12,
    },
    {
      id: 8,
      text: "12\\5",
      cycle: CYCLES.FIFTH_WEEK_CYCLE_12,
    },
    {
      id: 9,
      text: "12\\6",
      cycle: CYCLES.SIXTH_WEEK_CYCLE_12,
    },
    {
      id: 10,
      text: "12\\7",
      cycle: CYCLES.SEVENTH_WEEK_CYCLE_12,
    },
    {
      id: 11,
      text: "12\\8",
      cycle: CYCLES.EIGHTH_WEEK_CYCLE_12,
    },
    {
      id: 12,
      text: "12\\9",
      cycle: CYCLES.NINTH_WEEK_CYCLE_12,
    },
    {
      id: 12,
      text: "12\\10",
      cycle: CYCLES.TENTH_WEEK_CYCLE_12,
    },
    {
      id: 14,
      text: "12\\11",
      cycle: CYCLES.ELEVENTH_WEEK_CYCLE_12,
    },
    {
      id: 15,
      text: "12\\12",
      cycle: CYCLES.TWELFTH_WEEK_CYCLE_12,
    },
  ];


  return (
    <CyclesUI items={items} selectedCycle={selectedCycle} onSelect={onSelect} />
  );
};
