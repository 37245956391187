import { PopupContext } from "app/context/PopupContext";
import { pull } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useListBranchesContractsQuery } from "shared/api/contract-group";
import {
  useCreateContractsProjectJobMutation,
  useLazyGetProjectJobQuery,
} from "shared/api/projects";
import { Button, ErrorText, Flex, Loader, PageShadow, Popup } from "shared/ui";

import { configColumns } from "./config";
import GroupsContext from "./context";
import { Table } from "./ui";

const LoadContractGroups = ({ project, navigateToProject = () => { } }) => {
  const { hidePopup } = useContext(PopupContext);
  const [selectedObject, setSelectedObject] = useState({});
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [editedBranches, setEditedBranches] = useState([]);
  const [isErrorText, setErrorText] = useState(false);
  const { data: branchesContracts, isFetching } =
    useListBranchesContractsQuery();
  const [createProjectJob, { isLoading: isCreateProjectJobLoading }] =
    useCreateContractsProjectJobMutation();
  const [getProjectJob, projectState] = useLazyGetProjectJobQuery({
    pollingInterval: 3000,
  });

  const resetContractsSelected = () => {
    setSelectedObject({});
  };

  const onAllContractsCheckboxClick = (checked, data) => {
    const newSelectedObject = { ...selectedObject };
    if (checked) {
      newSelectedObject[data.branchCode] = data.contractGroups.map(
        (group) => group.id,
      );
    } else {
      delete newSelectedObject[data.branchCode];
    }
    setSelectedObject(newSelectedObject);
  };

  const onContractCheckboxClick = (checked, branchCode, group) => {
    const newSelectedObject = { ...selectedObject };
    if (checked) {
      if (newSelectedObject[branchCode] === undefined) {
        newSelectedObject[branchCode] = [];
      }
      newSelectedObject[branchCode].push(group.id);
    } else if (newSelectedObject[branchCode].length === 1) {
      delete newSelectedObject[branchCode];
    } else pull(newSelectedObject[branchCode], group.id);
    setSelectedObject(newSelectedObject);
  };

  const onSubmit = async () => {
    const branchContractItems = Object.keys(selectedObject).map(
      (branchCode) => {
        const branch = branchesContracts.find(
          (item) => item.branchCode === branchCode,
        );
        return {
          branchCode,
          groupIds: branch.contractGroups
            .filter((contractGroup) =>
              selectedObject[branchCode].includes(contractGroup.id),
            )
            .map((group) => group.id)
            .flat(),
          contractCodes: branch.contractGroups
            .filter((contractGroup) =>
              selectedObject[branchCode].includes(contractGroup.id),
            )
            .map((group) => group.contractCodes)
            .flat(),
        };
      },
    );

    if (branchContractItems.length < 1) {
      return setErrorText(true);
    }
    const response = await createProjectJob({
      projectId: project.id,
      branchContractItems,
    });
    if (response.error) return null;

    await getProjectJob({
      projectId: project.id,
      jobId: response.data?.id,
    });

    return null;
  };

  useEffect(() => {
    if (projectState?.data?.status === "DONE") {
      hidePopup();
      navigateToProject();
    }
  }, [projectState?.data?.status, navigateToProject]);

  const data = React.useMemo(() => branchesContracts, [branchesContracts]);
  const columns = React.useMemo(() => configColumns, []);

  const contextValue = useMemo(
    () => ({
      selectedObject,
      selectedBranches,
      editedBranches,
      onContractCheckboxClick,
      onAllContractsCheckboxClick,
      resetContractsSelected,
      setSelectedBranches,
      setEditedBranches,
    }),
    [
      selectedObject,
      selectedBranches,
      editedBranches,
      onContractCheckboxClick,
      onAllContractsCheckboxClick,
      resetContractsSelected,
      setSelectedBranches,
      setEditedBranches,
    ],
  );

  if (
    isFetching ||
    isCreateProjectJobLoading ||
    projectState?.endpointName === "getProjectJob"
  ) {
    return (
      <PageShadow>
        <Loader isLoading />
      </PageShadow>
    );
  }

  return (
    <Popup isCenter title="Получить данные по группам контрактов">
      <Flex height="1px" bg="color4" mt="20px" />
      <GroupsContext.Provider value={contextValue}>
        <Table data={data} columns={columns} />
      </GroupsContext.Provider>
      <Flex flexDirection="row" justifyContent="center">
        <Button text="ЗАГРУЗИТЬ ДАННЫЕ" width="223px" onClick={onSubmit} />
      </Flex>
      <ErrorText
        errorText={
          isErrorText
            ? "Для выбора филиала, распределите все его контракты по зонам"
            : ""
        }
      />
    </Popup>
  );
};

export default LoadContractGroups;
