import { PopupContext } from "app/context/PopupContext";
import {
  resetSelectedRoutePoints,
  setAllPointsChecked,
} from "app/store/reducer/days";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useListMapRoutePointsDaysQuery,
  useSetCycleMutation,
} from "shared/api/days";
import { useListFiltersRoutesUsersDuplicatesQuery } from "shared/api/filters";
import { Button, CustomRadio, Flex, Popup } from "shared/ui";
import styled from "styled-components";

const FOR_WEEK_СYCLES = [
  {
    label: "Каждая",
    name: "EVERY_WEEK"
  },
  {
    label: "Чётная",
    name: "EVEN_WEEK"
  },
  {
    label: "Нечётная",
    name: "ODD_WEEK"
  },
  {
    label: "Первая",
    name: "FIRST_WEEK"
  },
  {
    label: "Вторая",
    name: "SECOND_WEEK"
  },
  {
    label: "Третья",
    name: "THIRD_WEEK"
  },
  {
    label: "Четвертая",
    name: "FOURTH_WEEK"
  },
];



const FIRTY_WEEK_CYCLES = [
  // {
  //   label: "Каждая",
  //   name: "EVERY_WEEK_CYCLE_12"
  // },
  // {
  //   label: "Чётная",
  //   name: "EVEN_WEEK_CYCLE_12"
  // },
  // {
  //   label: "Нечётная",
  //   name: "ODD_WEEK_CYCLE_12"
  // },
  {
    label: "Первая",
    name: "FIRST_WEEK_CYCLE_12"
  },
  {
    label: "Вторая",
    name: "SECOND_WEEK_CYCLE_12"
  },
  {
    label: "Третья",
    name: "THIRD_WEEK_CYCLE_12"
  },
  {
    label: "Четвертая",
    name: "FOURTH_WEEK_CYCLE_12"
  },
  {
    label: "Пятая",
    name: "FIFTH_WEEK_CYCLE_12"
  },
  {
    label: "Шестая",
    name: "SIXTH_WEEK_CYCLE_12"
  },
  {
    label: "Седьмая",
    name: "SEVENTH_WEEK_CYCLE_12"
  },
  {
    label: "Восьмая",
    name: "EIGHTH_WEEK_CYCLE_12"
  },
  {
    label: "Девятая",
    name: "NINTH_WEEK_CYCLE_12"
  },
  {
    label: "Десятая",
    name: "TENTH_WEEK_CYCLE_12"
  },
  {
    label: "Одиннадцатая",
    name: "ELEVENTH_WEEK_CYCLE_12"
  },
  {
    label: "Двенадцатая",
    name: "TWELFTH_WEEK_CYCLE_12"
  }
];



const SetCycle = () => {
  const dispatch = useDispatch();
  const { hidePopup } = useContext(PopupContext);
  const [selectedCycle, setSelectedCycle] = useState("");
  const [cyclesVariant, setCyclesVariant] = useState('for')

  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { selectedRoutePoints, frontendFilters, allPointsChecked } =
    useSelector((state) => state.rootReducer.days);
  const { data: filters, isSuccess } = useListFiltersRoutesUsersDuplicatesQuery(
    {
      projectId: projectInfo.id,
      managers: frontendFilters.managerCodes,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );
  const { data: mapRoutePoints } = useListMapRoutePointsDaysQuery(
    {
      projectId: projectInfo.id,
      managerCodes: frontendFilters?.managerCodes,
      commands: frontendFilters?.commands,
      routeCodes: frontendFilters?.routeCodes,
      daysOfWeek: frontendFilters?.daysOfWeek,
      cyclePoint: frontendFilters?.cyclePoint,
    },
    { skip: !projectInfo.id },
  );
  const [setCycle, { isLoading }] = useSetCycleMutation();

  const onSubmit = async () => {
    const routeId = filters?.routes.find(
      (item) => item.routeCode === frontendFilters.routeCodes[0],
    ).routeId;

    await setCycle({
      projectId: projectInfo.id,
      routeId,
      routePointIdList: allPointsChecked
        ? mapRoutePoints.map((point) => point.id)
        : selectedRoutePoints,
      cyclePoint: selectedCycle,
    });

    dispatch(
      allPointsChecked
        ? setAllPointsChecked(false)
        : resetSelectedRoutePoints(),
    );
    hidePopup();
  };

  if (isSuccess) {
    return (
      <Popup title="Задать цикл" isCenter={false} positionLeft="120px" scrolled>
        <div>

        <ListWrapper>
          {(cyclesVariant === 'for' ? FOR_WEEK_СYCLES : FIRTY_WEEK_CYCLES).map((cycle) => (
            <CustomRadio
              key={cycle.name}
              className="customRadio"
              label={cycle.label}
              name="cycle"
              onChange={() => setSelectedCycle(cycle.name)}
            />
          ))}
        </ListWrapper>
        <Flex mt={1} mb={2}>
          <CustomRadio
            className="customRadio"
            label='4х недельный цикл'
            name="cycle_type"
            checked={cyclesVariant === 'for'}
            onChange={() => setCyclesVariant('for')}
          />
          <CustomRadio
            className="customRadio"
            label="12ти недельный цикл"
            name="cycle_type"
            checked={cyclesVariant === 'thirteen'}
            onChange={() => setCyclesVariant('thirteen')}
          />
        </Flex>
        <Button
          text="Применить"
          onClick={onSubmit}
          disabled={!selectedCycle || isLoading}
        />
        </div>

      </Popup>
    );
  }

  return null;
};

const ListWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .customRadio {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default SetCycle;
