import { Cross, Maximize, Minimize, Refresh } from "app/assets/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useListPointsReportQuery } from "shared/api/days";
import { useListFiltersRoutesUsersDuplicatesQuery } from "shared/api/filters";
import { Button, Flex, Loader, Text, Select } from "shared/ui";

import { CYCLE_TYPES } from "shared/config";

import styled from "styled-components";

import { configColumns } from "./config";
import Table from "./ui/table";

const Report = ({ setIsReportOpen = () => { } }) => {
  const [isExpand, setIsExpand] = useState(false);
  const [routeId, setRouteId] = useState(null);
  const cycleTypes = Object.entries(CYCLE_TYPES);

  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const [selectedCycle, setSelectedCycle] = useState(cycleTypes[0][0]);


  const { frontendFilters } = useSelector((state) => state.rootReducer.days);
  const {
    data: report,
    isFetching,
    refetch,
  } = useListPointsReportQuery(
    {
      projectId: projectInfo.id,
      routeId,
      dayOfWeek: frontendFilters.daysOfWeek[0],
      cycle: selectedCycle
    },
    { skip: !projectInfo.id || !routeId || !frontendFilters.daysOfWeek[0] },
  );

  const { data: filters } = useListFiltersRoutesUsersDuplicatesQuery(
    {
      projectId: projectInfo.id,
      managers: frontendFilters.managerCodes,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (filters && frontendFilters.routeCodes[0]) {
      setRouteId(
        filters.routes.find(
          (route) => route.routeCode === frontendFilters.routeCodes[0],
        ).routeId,
      );
    }
  }, [filters, frontendFilters.routeCodes]);

  useEffect(() => {
    refetch()
  }, [selectedCycle]);

  const data = React.useMemo(() => report, [report]);
  const columns = React.useMemo(() => configColumns, []);

  if (isFetching) {
    return (
      <Root>
        <Header>
          <Text variant="title1">Маршруты дня по неделям</Text>
        </Header>
        <Flex flexDirection="row" height="200px">
          <Loader isLoading />
        </Flex>
      </Root>
    );
  }

  return (
    <Root>
      <Header>
        <Text variant="title1">Маршруты дня по неделям</Text>
      </Header>
      <Table data={data} columns={columns} isExpand={isExpand} />
      <Flex flexDirection="row" mt="20px" alignItems='end'>
        <Button text="ОБНОВИТЬ" icon={<Refresh />} onClick={() => refetch()} />
        <div style={{
          marginTop: -6,
          marginLeft: 6,
          maxWidth: 160,
        }}>
          <Select

            title="Выберите цикл"
            options={cycleTypes.map(([key, value]) => ({
              id: key,
              name: value,
              value: key,
            }))}
            value={selectedCycle}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setSelectedCycle(selectedValue);
            }}
          />
        </div>


        <CustomButton
          className="expandButton"
          onClick={() => setIsExpand(!isExpand)}
        >
          {isExpand ? <Minimize /> : <Maximize />}
        </CustomButton>
        <CustomButton
          className="closeButton"
          onClick={() => setIsReportOpen(false)}
        >
          <Cross />
        </CustomButton>
      </Flex>
    </Root>
  );
};

const Root = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: #fff;
  z-index: 9999;
  box-shadow: 0px -8px 10px -4px rgba(0, 0, 0, 0.1);
  width: 50%;
  min-width: 40vw;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const CustomButton = styled.button`
  background: #ecf4fe;
  border-radius: 12px;
  width: 56px;
  height: 48px;
  border: none;
  outline: none;
  color: #2d8af6;

  &.expandButton {
    margin-left: auto;
  }

  &.closeButton {
    margin-left: 8px;
  }
`;

export default Report;
